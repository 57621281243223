import first from 'lodash/first';
import get from 'lodash/get';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';
import map from 'lodash/map';
import toString from 'lodash/toString';

const userValidationsMap = {
  age_under_requirements: {
    errorMessage: {
      entity: 'csOperatorConfiguration',
      entityKey: 'min_driver_age',
      phraseAppKeyParam: 'years',
    },
  },
  novel_driving_license: {
    errorMessage: {
      entity: 'csOperatorConfiguration',
      entityKey: 'min_license_years',
      phraseAppKeyParam: 'years',
    },
  },
};

/**
 * Check if a user has user validations failed to do a booking
 * @param {array} userValidations
 * @return {string}
 */
export const validateBookingConditions = (userValidations = []) => first(
  intersection(
    keys(userValidationsMap),
    map(userValidations, 'status'),
  ),
);

/**
 * Return params to compose phraseapp value
 * @param {string} userValidationKey
 * @param {object} operatorConfig
 * @return {object}
 */
export const validationErrorMessageParams = (userValidationKey, operatorConfig) => {
  const { entityKey, phraseAppKeyParam } = get(userValidationsMap, `${userValidationKey}.errorMessage`, {});
  const operatorConfigValue = toString(get(operatorConfig, entityKey));

  return isEmpty(phraseAppKeyParam) || isEmpty(operatorConfigValue)
    ? {}
    : { [phraseAppKeyParam]: operatorConfigValue };
};

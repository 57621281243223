import moment from 'moment';
import defaults from 'lodash/defaults';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import isNil from 'lodash/isNil';
import { DATE_FORMAT, FALLBACK_MESSAGE } from '@emobg/web-utils';

export const printableAddress = (location, options = {}) => {
  if (!isObject(location)) {
    return '';
  }
  const checkedOptions = defaults(options, {
    checkAddress: false,
  });

  const {
    display_address: displayAddress = '',
    name: firstAddress = '',
    address = '',
  } = location;

  let secondAddress = checkedOptions.checkAddress
    ? displayAddress || address
    : displayAddress;

  if (isNil(secondAddress)) {
    secondAddress = '';
  }

  const isSame = firstAddress === secondAddress;

  const bothAddress = firstAddress && secondAddress
    ? `${firstAddress} | ${secondAddress}`
    : `${firstAddress}${secondAddress}`;

  return isSame ? firstAddress : bothAddress;
};

export const printableDate = (param = '') => {
  const dateFormat = `${DATE_FORMAT.timeOfDay} - ${DATE_FORMAT.dob}`;

  const fallback = moment.isMoment(param) ? param : moment.invalid();
  const date = isString(param) ? moment(param) : fallback;

  return date.isValid() ? date.format(dateFormat) : FALLBACK_MESSAGE.dash;
};

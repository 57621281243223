import get from 'lodash/get';
import set from 'lodash/set';
import size from 'lodash/size';

import { LOG_TYPE, logger } from '@emobg/web-utils';
import { getOperator } from '@/stores/Operator/OperatorMapper.js';

/**
 * Validates employee regular and restrictiveBadge
 * @param {Object} location Location Object
 * @returns {Boolean} isRegularBadgeOk && isRestrictiveBadgeOk
 */
export const employeeBadgeValidation = ({ user_documents: userDocuments, cs_operator_requirements: csOperatorRequirements }) => {
  // What is restrictive badge?
  // Normally this type of badge is for Companies and they use other
  // type of validation. If company does not need a restrictive badge
  // we have to validate the user with the normal flow
  const isRestrictiveBadgeNeeded
    = get(csOperatorRequirements, 'has_badge', true)
    && get(csOperatorRequirements, 'is_company_restrictive_badge', false);
  const userHasRestrictiveBadge = get(userDocuments, 'has_badge');
  const isRestrictiveBadgeOk = (isRestrictiveBadgeNeeded && userHasRestrictiveBadge) || !isRestrictiveBadgeNeeded;
  const isBadgeRequired = get(csOperatorRequirements, 'has_badge', true);
  const userHasBadge = get(userDocuments, 'has_badge', false);
  const isRegularBadgeOk = (isBadgeRequired && userHasBadge) || !isBadgeRequired;
  return isRegularBadgeOk && isRestrictiveBadgeOk;
};

/**
 * Validates if the employee driving license is correct
 * @param {Object} location Provides user information about driving license and his badges
 * @param {Boolean} isDrivingLicenseReviewAutorenewed
 * @returns {Boolean} isDrivingLicenseRequiredOk && isDrivingLicenseReviewOk;
 */
export const drivingLicenseValidation = (
  { user_documents: userDocuments, cs_operator_requirements: csOperatorRequirements },
  isDrivingLicenseReviewAutorenewed,
) => {
  // What is driving license review?
  // Employees need to show the driving license card to the admin
  // and he/she will validate from Company admin. This review
  // could be periodically or not.
  const isDrivingLicenseReviewNeeded
    = get(csOperatorRequirements, 'requires_driving_license_validation', false);

  // We just check when driving license review/card is valid and badge
  // Doesn't matter if it will expire soon or not
  const isDLGoingToExpire = false;

  const isDrivingLicenseValidated
    = get(userDocuments, 'driving_license_validated', false);

  const wasDrivingLicenseValidatedOnce
    = get(userDocuments, 'validated_once', false);
  /**
   * DOC: https://europcarmobility.atlassian.net/browse/CSS2-496
   * Some CS Operators can renew automatically the driving license review
   * when employees make bookings. We will not check if it is valid
   * or not when it has been configured and DL review was validated
   * once sometime ago.
   */
  const userHasDrivingLicenseReview
    = (isDrivingLicenseReviewAutorenewed && wasDrivingLicenseValidatedOnce)
    || (isDrivingLicenseValidated && !isDLGoingToExpire);

  const isDrivingLicenseReviewOk
    = (isDrivingLicenseReviewNeeded && userHasDrivingLicenseReview)
    || !isDrivingLicenseReviewNeeded;

  const isDLRequired = get(csOperatorRequirements, 'has_driving_license', true);
  const hasDrivingLicense = get(userDocuments, 'has_driving_license', false);

  const isDrivingLicenseRequiredOk = (isDLRequired && hasDrivingLicense) || !isDLRequired;
  return isDrivingLicenseRequiredOk && isDrivingLicenseReviewOk;
};
export const locationValidation = async (location, csOperatorUuid) => {
  let isDrivingLicenseReviewAutorenewed = false;
  if (csOperatorUuid) {
    try {
      const csOperator = getOperator(csOperatorUuid);
      isDrivingLicenseReviewAutorenewed
      = get(csOperator, 'configuration.allow_automatic_dl_review_renewal');
    } catch (error) {
      const message = get(error, 'response.data.message', error.message);
      logger.message(`isDriverLicenseAutorenewal could not be defined: ${message}`, LOG_TYPE.error);
    }
  }
  const isBadgeOk = employeeBadgeValidation(location);
  const isDrivingLicenseOk = drivingLicenseValidation(location, isDrivingLicenseReviewAutorenewed);
  return !(isBadgeOk && isDrivingLicenseOk);
};

export const validateLocationsForEmployee = async locations => {
  let validatedLocations = null;
  if (size(locations)) {
    const mapping = locations.map(async location => {
      const csOperatorUuid = get(location, 'cs_operator_uuid');
      const hasInvalidDocuments = await locationValidation(location, csOperatorUuid);
      set(location, 'user_documents.hasInvalidDocuments', hasInvalidDocuments);
      return location;
    });

    validatedLocations = await Promise.all(mapping);
  }
  return validatedLocations;
};

import get from 'lodash/get';
import { isBookingCloseIntoFuture } from '@/helpers/booking/bookingHelpers';
import { isDangerousLevel, isMissingBattery } from '@/utils/ev-helpers';

/**
 * Return if booking start is close into the future and battery level is at dangerous level
 * @param {string} bookingStart
 * @param {object} operator - { configuration: { close_into_future: 30 } }
 * @param {object} electricDetails - { low_battery_level: 20, power_battery_level: 25 }
 * @return {boolean}
 */
export const isLowBattery = (bookingStart, operator, electricDetails) => {
  const lowBatteryLevel = get(electricDetails, 'low_battery_level', null);
  const powerBatteryLevel = get(electricDetails, 'power_battery_level', null);

  const hasBattery = !isMissingBattery(powerBatteryLevel);

  return hasBattery
    && isDangerousLevel(powerBatteryLevel, lowBatteryLevel)
    && isBookingCloseIntoFuture({ start: bookingStart }, operator);
};

/**
 * Return if vehicle is electric and the booking is created not close into the future
 * @param {string} bookingStart
 * @param {object} operator - { configuration: { close_into_future: 30 } }
 * @param {object} electricDetails - { low_battery_level: 20, power_battery_level: 25 }
 * @return {boolean}
 */
export const isFutureBooking = (bookingStart, operator, electricDetails = null) => {
  const powerBatteryLevel = get(electricDetails, 'power_battery_level', null);

  const hasBattery = !isMissingBattery(powerBatteryLevel);

  return hasBattery && !isBookingCloseIntoFuture({ start: bookingStart }, operator);
};
